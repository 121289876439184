<template>
  <div>
    <div class="table-page-search-wrapper">
      <a-form layout="inline">
        <a-row :gutter="48">
          <a-col :md="6" :sm="24">
            <a-form-item label="项目">
              <a-select
                option-label-prop="tt"
                :show-arrow="false"
                :filter-option="false"
                :not-found-content="null"
                show-search
                allow-clear
                v-model="queryParam.project_id"
                placeholder="请输入项目"
                :default-active-first-option="false"
                @search="this.projectSearch"
                @change="handleProjectChange"
              >
                <a-select-option v-for="d in projectList" :key="d.id" :tt="d.name">
                  {{ d.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="4" :sm="24">
            <a-form-item label="客户">
              <a-select
                option-label-prop="tt"
                :show-arrow="false"
                :filter-option="false"
                :not-found-content="null"
                show-search
                allow-clear
                v-model="queryParam.client_id"
                placeholder="请输入客户"
                :default-active-first-option="false"
                @search="this.clientSearch"
                @change="handleClientChange"
              >
                <a-select-option v-for="d in clientList" :key="d.id" :tt="d.name">
                  {{ d.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="4" :sm="24">
            <a-form-item label="创建人">
              <a-input v-model="queryParam.create_by" placeholder="创建人" allow-clear/>
            </a-form-item>
          </a-col>
          <a-col :md="2" :sm="24">
            <span class="table-page-search-submitButtons">
              <a-button type="primary" @click="$refs.table.refresh(true)">查询</a-button>
            </span>
          </a-col>
          <a-col :md="2" :sm="24" v-if="false">
            <span class="table-page-search-submitButtons">
              <a-button type="primary" @click="handleArchived">批量存档</a-button>
            </span>
          </a-col>
          <a-col :md="2" :sm="24" v-if="false">
            <span class="table-page-search-submitButtons">
              <a-button type="primary" @click="handleAdd">新建</a-button>
            </span>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <s-table
      ref="table"
      size="small"
      rowKey="id"
      :loading="loading"
      :columns="columns"
      :data="loadData"
      :scroll="{ x: 1200 }"
    >
      <span slot="serial" slot-scope="text, record, index">
        {{ index + 1 }}
      </span>
      <span slot="approval_needed" slot-scope="text">
        <span class="desc">{{ text ? '是' : '否' }}</span>
      </span>
      <div slot="money_render" slot-scope="text" style="text-align: right; min-width: 90px; max-width: 200px">
        {{ (text / 1).toFixed(2) | numberFormat }}
      </div>
      <span slot="action" slot-scope="text, record">
        <template>
          <a @click="handleAttachment(record)">附件</a>
          <a-divider type="vertical" />
          <a @click="handleEdit(record)">编辑</a>
          <a-divider type="vertical" />
          <a-popconfirm
            title="确定要删除么？"
            ok-text="确定"
            cancel-text="取消"
            @confirm="handleDelete(record)"
          >
            <a>删除</a>
          </a-popconfirm>
          <a-divider type="vertical" />
          <a @click="handleDetail(record)">详情</a>
        </template>
      </span>
    </s-table>
    <create-form
      ref="createModal"
      v-if="visible"
      :visible="visible"
      :loading="confirmLoading"
      :model="mdl"
      @cancel="handleAddCancel"
      @ok="handleOk"
    />
    <archived-form
      ref="archivedModal"
      :visible="archived_visible"
      @cancel="handleArchivedCancel"
      @ok="handleArchivedOK"
    />
  </div>
</template>

<script>
import { STable } from '@/components'
import CreateForm from '../modules/CreateForm'
import ArchivedForm from '../modules/ArchivedForm'
import { fapiao_list, fapiao_create, fapiao_delete, fapiao_archived, fapiao_update } from '@/api/trade_fapiao'
import debounce from 'lodash/debounce'
import { project_list } from '@/api/trade_project'
import { client_list } from '@/api/trade_client'
import { createProcessResult, getForms } from '@/api/trade_design'

export default {
  name: 'TableList',
  components: {
    STable,
    CreateForm,
    ArchivedForm
  },
  data () {
    this.projectSearch = debounce(this.handleProjectSearch, 800)
    this.clientSearch = debounce(this.handleClientSearch, 800)
    return {
      loading: false,
      confirmLoading: false,
      // 创建窗口控制
      visible: false,
      archived_visible: false,
      mdl: {},
      projectList: [],
      clientList: [],
      // 查询参数
      queryParam: {},
      // 表头
      columns: [
        {
          title: '序号',
          width: 50,
          scopedSlots: { customRender: 'serial' }
        },
        {
          title: '项目',
          dataIndex: 'project',
          width: 150,
          ellipsis: true
        },
        {
          title: '客户',
          dataIndex: 'client',
          width: 150,
          ellipsis: true
        },
        {
          title: '金额',
          dataIndex: 'total_price',
          scopedSlots: { customRender: 'money_render' },
          align: 'right',
          width: 180,
          ellipsis: true
        },
        {
          title: '税额',
          dataIndex: 'total_tax',
          scopedSlots: { customRender: 'money_render' },
          align: 'right',
          width: 150,
          ellipsis: true
        },
        {
          title: '发票方式',
          dataIndex: 'method',
          ellipsis: true,
          align: 'center',
          width: 150,
          customRender: (text) => this.$Dictionaries.trade_fapiao_method[text] || '无'
        },
        {
          title: '备注',
          dataIndex: 'remark',
          width: 150,
          ellipsis: true
        },
        {
          title: '开启审批',
          dataIndex: 'is_approval_needed',
          ellipsis: true,
          align: 'center',
          width: 120,
          scopedSlots: { customRender: 'approval_needed' }
        },
        {
          title: '已存档',
          dataIndex: 'is_archived',
          ellipsis: true,
          align: 'center',
          width: 120,
          customRender: (text) => text ? '是' : '否'
        },
        {
          title: '审批状态',
          dataIndex: 'status',
          align: 'center',
          width: 100,
          customRender: (text, record) => record.is_approval_needed ? this.$Dictionaries.approval_result[text] : '未开启审批'
        },
        {
          title: '创建人',
          dataIndex: 'create_by',
          width: 150,
          ellipsis: true
        }
        // {
        //   title: '操作',
        //   dataIndex: 'action',
        //   width: '180px',
        //   scopedSlots: { customRender: 'action' }
        // }
      ],
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        console.log('loadData.parameter', parameter)
        return fapiao_list(Object.assign(parameter, this.queryParam))
          .then(res => {
            console.log(res.data, '我是数据列表@')
            return res.data
          })
      },
      selectedRowKeys: [],
      selectedRows: [],

      // custom table alert & rowSelection
      options: {
        alert: { show: true, clear: () => { this.selectedRowKeys = [] } },
        rowSelection: {
          getCheckboxProps: record => ({
            props: {
              disabled: record.is_archived
            }
          }),
          selectedRowKeys: this.selectedRowKeys,
          onChange: this.onSelectChange
        }
      },
      optionAlertShow: false
    }
  },
  created () {
    this.tableOption()
    this.handleProjectSearch(undefined)
    this.handleClientSearch(undefined)
  },
  methods: {
    tableOption () {
      if (!this.optionAlertShow) {
        this.options = {
          alert: { show: true, clear: () => { this.selectedRowKeys = [] } },
          rowSelection: {
            getCheckboxProps: record => ({
              props: {
                disabled: record.is_archived
              }
            }),
            selectedRowKeys: this.selectedRowKeys,
            onChange: this.onSelectChange
          }
        }
        this.optionAlertShow = true
      } else {
        this.options = {
          alert: false,
          rowSelection: null
        }
        this.optionAlertShow = false
      }
    },
    handleAdd () {
      this.mdl = null
      this.visible = true
    },
    handleArchived () {
      if (this.selectedRowKeys.length > 0) {
        this.archived_visible = true
      } else {
        this.$message.info('选择存档记录')
      }
    },
    handleAddCancel () {
      this.visible = false
      const form = this.$refs.createModal.form
      form.resetFields() // 清理表单数据（可不做）
    },
    handleArchivedCancel () {
      this.archived_visible = false
      const form = this.$refs.archivedModal.form
      form.resetFields() // 清理表单数据（可不做）
    },
    handleAttachment (record) {
      // this.$emit('onEdit', record)
      this.$router.push({ path: '/trade/fapiao/' + record.id + '/attachment', query: { project: record.project, client: record.client } })
    },
    handleEdit (record) {
      // this.$emit('onEdit', record)
      this.$router.push({ path: '/trade/fapiao/' + record.id })
    },
    handleDetail (record) {
      this.$router.push({ path: '/trade/fapiao/' + record.id + '/fapiao_detail_list', query: { project: record.project, client: record.client } })
    },
    approvalNeeded (record) {
      fapiao_update({ is_approval_needed: !record.is_approval_needed }, record.id).then((res) => {
        if (res.code === 1000) {
          this.$refs.table.refresh()
          if (!record.is_approval_needed) {
            this.geneApprovalInfo(res.data.id)
          }
        }
      })
    },
    handleDelete (record) {
      fapiao_delete(record.id).then((res) => {
        console.log(res)
        console.log('删除成功------')

        this.$refs.table.refresh()
      })
    },
    handleOk () {
      const form = this.$refs.createModal.form
      this.confirmLoading = true
      form.validateFields((errors, values) => {
        if (!errors) {
          console.log('values', values)
            // 新增
          fapiao_create(values).then(res => {
              this.visible = false
              this.confirmLoading = false
              // 重置表单数据
              form.resetFields()
              // 刷新表格
            if (res.code === 1000) {
              this.$refs.table.refresh()
              if (values.is_approval_needed) {
                this.geneApprovalInfo(res.data.id)
              }
            }
          })
        } else {
          this.confirmLoading = false
        }
      })
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    },
    handleProjectSearch (value) {
      project_list({ name: value }).then((res) => {
        const result = res.data.entries || []
        this.projectList = !value ? (result.length > 5 ? result.slice(0, 5) : result) : result
      })
    },
    handleProjectChange (value) {
      console.log(value)
      if (value === undefined) {
        this.handleProjectSearch(value)
      }
    },
    handleClientSearch (value) {
      client_list({ name: value }).then((res) => {
        const result = res.data.entries || []
        this.clientList = !value ? (result.length > 5 ? result.slice(0, 5) : result) : result
      })
    },
    handleClientChange (value) {
      console.log(value)
      if (value === undefined) {
        this.handleClientSearch(value)
      }
    },
    handleArchivedOK () {
      const form = this.$refs.archivedModal.form
      this.confirmLoading = true
      form.validateFields((errors, values) => {
        if (!errors) {
          const data = {
            ids: this.selectedRowKeys,
            ...values
          }
          fapiao_archived(data).then(res => {
            // 刷新表格
            if (res.code === 1000) {
              this.$refs.table.refresh()
            }
          }).finally(() => {
            this.archived_visible = false
            this.confirmLoading = false
          })
        } else {
          this.confirmLoading = false
        }
      })
    },
    geneApprovalInfo (id) {
      this.createContractApproval(id, 'trade_fapiao')
    },
    createContractApproval (id, type) {
      getForms({ is_stop: 0, template_type: type, data_id: id }).then(res => {
        console.log(res)
        const shenpi_list = res.data.entries
        if (shenpi_list && shenpi_list.length > 0) {
          for (let i = 0, len = shenpi_list.length; i < len; i++) {
            const item = shenpi_list[i]
            const settings = JSON.parse(item.settings)
            if (settings) {
              const notify = settings.notify
              if (notify) {
                const types = notify.types
                if (types) {
                  if (types.type === type) {
                    this.createProcessNotice(item.id, id, type)
                    break
                  }
                }
              }
            }
          }
        } else {
          this.$message.warning('先创建审批模板')
          // this.createProcessNotice(-1, ids, type)
        }
      })
    },
    createProcessNotice (template_id, ids, type) {
      const data = {
        'data_ids': [ids],
        'template_id': template_id,
        'template_type': type,
        'approval_again': this.approval_again
      }
      createProcessResult(data).then(res => {
        console.log(res)
        if (res.code === 1000) {
          console.log('创建成功')
          this.$refs.table.refresh()
        }
      })
    }

  }
}
</script>
